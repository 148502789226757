import React from "react";
import "./App.css";
import logo from "../src/Assets/Logo/Logo.webp";
import gif from "../src/Assets/Gif/hogwarts-maintenance.gif";

function App() {
  return (
    <>
      <section>
        <img src={logo} alt="Logo" className="logo" />
      </section>
      <section>
        <img src={gif} alt="gif" className="gif" />
      </section>
    </>
  );
}

export default App;
